import React, { useState } from 'react';
import { PreloadImg } from 'utils';
import './css/FestivalLogo.css';

export default ({
  link,
  festival,
  logoHover,
  logo,
}) => {
  const [linkStyle, setLinkStyle] = useState(logo);
  return (
    <div className="festivalLogo">
      <PreloadImg alt="logoHover" src={logoHover} />
      <PreloadImg alt="logo" src={logo} />
      <div className="small_logoHelper">
        <a href={link} target="_blank" rel="noreferrer">
          <div onMouseEnter={() => setLinkStyle(logoHover)} onMouseLeave={() => setLinkStyle(logo)} className="festivalLogoDiv">
            <img className="festivalLogoImg" src={linkStyle} alt={festival} />
          </div>
        </a>
      </div>
    </div>
  );
};
