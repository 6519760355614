import React, { useState, useEffect } from 'react';
import './css/Contact.css';
import { mediaLink } from 'utils';
import logoMask100 from 'static/logo/logo_mask_100.png';
import logoMask150 from 'static/logo/logo_mask_150.png';

export default ({ name, image, navn, funktion, email, telefon }) => {
  const [mask, setMask] = useState(logoMask150);
  const windowResize = () => {
    setMask(window.innerWidth > 500 ? logoMask150 : logoMask100);
  };
  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  });
  return (
    <div className="contact_person">
      <img
        style={{
          maskImage: `url(${mask})`,
          WebkitMaskImage: `url(${mask})`,
        }}
        className="contact_person_img"
        alt={name}
        src={mediaLink(image)}
      />
      <div className="contact_person_text">
        <p className="contact_person_p">{navn}</p>
        <p className="contact_person_p">{funktion}</p>
        {telefon !== '' ? (
          <p className="contact_person_p">
            <a className="contact_person_p_a" href={`tel:${telefon}`}>
              {telefon}
            </a>
          </p>
        ) : null}
        <p
          className="contact_person_p"
          style={email?.length > 25 ? { fontSize: 15 } : null}
        >
          <a className="contact_person_p_a" href={`mailto:${email}`}>
            {email}
          </a>
        </p>
      </div>
    </div>
  );
};
