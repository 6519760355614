/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import DirectMenuPointLogo from './DirectMenuPointLogo';

// import '../css/FestivalLogo.css';

const SubMenuPoint = ({ obj }) => (
  <div className="menuFlexPoint">
    <p className="hassubmenu menuPoint_a menuPoint_p">{obj.submenu.name}</p>
  </div>
);

const DirectMenuPoint = ({ obj }) => {
  const { title } = obj;
  let { slug } = obj;
  slug = title === 'News' ? slug : `/page/${slug}`;
  return (
    <div className="menuFlexPoint">
      <Link className="menuPoint_a" to={slug}>
        <p className="menuPoint">{title}</p>
      </Link>
    </div>
  );
};

export default ({ history, match, sitemap }) => {
  const [submenu, setSubmenu] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [position, setPosition] = useState('absolute');
  const [pages, setPages] = useState(null);
  const [activeSub, setActiveSub] = useState(null);
  const [menuPoints, setMenuPoints] = useState(null);
  const closeSub = () => {
    setOpacity(0);
    setHeight(0);
    setSubmenuOpen(false);
    setSubmenu(null);
  };
  const onClickMain = (e) => {
    const targetName = e.target.innerHTML;
    if (submenuOpen && targetName === activeSub) closeSub();
    else if (e.target.className.includes('hassubmenu')) {
      let i;
      let sub;
      for (i = 0; i < pages.length; i += 1) {
        sub = pages[i].submenu;
        if (sub && sub.name === targetName) break;
      }
      setSubmenu(
        sub.regularpage.map((obj) => (
          <DirectMenuPoint obj={obj} key={obj.title} />
        ))
      );
      setActiveSub(sub.name);
      setSubmenuOpen(true);
      setHeight(100);
      setOpacity(1);
    }
  };
  const onClickSub = (e) => {
    if (e.target.className.includes('menuPoint') && submenuOpen) closeSub();
  };
  useEffect(() => {
    const update = () => {
      const index = history.location.pathname === '/';
      setBackgroundColor(index ? 'transparent' : '#20201e');
      setPosition(index ? 'absolute' : 'relative');
    };
    update();
    if (sitemap) {
      const home = { regularpage: { title: 'Home', slug: '/', link: '/' } };
      const news = {
        regularpage: { title: 'News', slug: '/news', link: '/news' },
      };
      const mergedPagesTop = sitemap.hasNews
        ? sitemap.sitemap.concat(news)
        : sitemap.sitemap;
      const mergedPages = [home].concat(mergedPagesTop);
      setPages(mergedPages);
      setMenuPoints(
        mergedPages.map((obj) => {
          if (!obj.submenu) {
            return obj.regularpage.title === 'Home' ? (
              <DirectMenuPointLogo
                obj={obj.regularpage}
                key={obj.regularpage.title}
              />
            ) : (
              <DirectMenuPoint
                obj={obj.regularpage}
                key={obj.regularpage.title}
              />
            );
          }
          return <SubMenuPoint obj={obj} key={obj.submenu.name} />;
        })
      );
    }
    return () => {
      update();
    };
  }, [match, history, sitemap]);
  const subStyle = {
    backgroundColor,
    height,
    opacity,
    position,
    width: '100%',
  };
  return (
    <div className="topmenu">
      <div
        onClick={onClickMain}
        className="menuFlex"
        style={{ backgroundColor }}
        name="menuFlex"
      >
        {menuPoints}
      </div>
      <div
        onClick={onClickSub}
        className="menuFlex"
        name="overlay"
        style={subStyle}
      >
        {submenu}
      </div>
    </div>
  );
};
