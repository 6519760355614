import React from 'react';
import { useImage } from 'hooks';
import './css/Partners.css';

export default ({ 0: { value: partners } }) => (
  <div>
    <p className="picture_text" style={{ height: 40 }}>
      Partners
    </p>
    <div className="partners_in">
      <div className="partners">
        {partners.map(({ name, image }) => {
          const [src] = useImage(image);
          return (
            <div className="partners_partner">
              <img className="partners_partner_img" src={src} alt={name} />
            </div>
          );
        })}
      </div>
    </div>
  </div>
);
