const toPos = (n) => `${Math.round(parseFloat(n)).toString()}%`;

const dP = '50%';

const getPos = (tags) => {
  try {
    return tags[0].split('-').map((n) => toPos(n));
  } catch (e) {
    return [dP, dP];
  }
};

export default getPos;
