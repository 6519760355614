/* eslint-disable */

import React, { useEffect } from 'react';
import 'components/blocks/css/Header_vid.css';

export default ({ src, vidIsPlaying }) => {
  useEffect(() => {
    const vid = document.querySelector('video');
    (function myLoop(i) {
      setTimeout(() => {
        if (
          vid.currentTime > 0 &&
          !vid.paused &&
          !vid.ended &&
          vid.readyState > 2
        ) {
          vidIsPlaying();
          return;
        }
        if (--i) myLoop(i);
      }, 10);
    })(1000);
  });
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
          <video
            object-fit
            class="head_video_inner"
            muted
            loop
            autoplay
            playsinline
          >
            <source src="${src}" type="video/mp4"/>
          </video>
        `,
      }}
    />
  );
};
