import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'components/blocks/css/Partners.css';
import { baseurl, mediaLink } from 'utils';

const Partner = ({ image }) => (
  <div className="partners_partner">
    <img className="partners_partner_img" src={mediaLink(image)} alt="d" />
  </div>
);

export default (props) => {
  const [logos, setLogos] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/frontend/partner/?format=json`;
    const source = axios.CancelToken.source();
    axios.get(url, { cancelToken: source.token }).then((res) => {
      setLogos(
        res.data.map((obj) => <Partner key={obj.name} image={obj.logo.big} />),
      );
    });
    return () => { source.cancel(); };
  }, [props]);
  return (
    <div>
      <p className="picture_text" style={{ height: 40 }}>Partners</p>
      <div className="partners_in">
        <div className="partners">
          {logos}
        </div>
      </div>
    </div>
  );
};
