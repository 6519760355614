import React, { useState, useEffect } from 'react';
import './css/Textbox.css';

export default ({ subtitle, text }) => {
  const [textElement, setTextElement] = useState(null);
  useEffect(() => {
    setTextElement(<p className="textbox_p" dangerouslySetInnerHTML={{ __html: text }} />);
  }, [subtitle, text]);
  return (
    <div className="textbox_outer">
      <div className="textbox">
        <p className="textbox_head">{subtitle}</p>
        {textElement}
      </div>
    </div>
  );
};
