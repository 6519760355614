import React from 'react';
import '../css/Picture.css';
import { useImage } from 'hooks';
import HeaderPicture from './HeaderPicture';

const HeaderPictureLogo = ({ image, logo }) => {
  const [logo2] = useImage(logo);
  return (
    <HeaderPicture image={image}>
      <img alt=" " className="header_logo" src={logo2} />
    </HeaderPicture>
  );
};

export default HeaderPictureLogo;
