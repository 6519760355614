import React from 'react';
import './css/Contact.css';

const GoogleMaps = ({
  city,
  address,
  postal,
  telephone,
  email,
  place_id,
}) => (
  <div className="googlemaps">
    <p className="googlemaps_by">{city}</p>
    <p className="googlemaps_p">{address}</p>
    <p className="googlemaps_p">{postal}</p>
    <p className="googlemaps_p">{city}</p>
    <p className="googlemaps_p">
      <a className="contact_person_p_a" href={`tel:+45${telephone}`}>
        {`+45 ${telephone}`}
      </a>
    </p>
    <p className="googlemaps_p">
      <a className="contact_person_p_a" href={`mailto:${email}`}>
        {email}
      </a>
    </p>
    <iframe
      title="video"
      width="90%"
      height="400"
      frameBorder="0"
      style={{ border: 0, marginTop: 10 }}
      src={`https://www.google.com/maps/embed/v1/place?q=place_id:${place_id}&key=AIzaSyDGt7Io1UUSRZNKY9lms2zO4P_DYWLGai4`}
      allowFullScreen
    />
  </div>
);

export default ({
  firstCity,
  firstAddress,
  firstPostal,
  firstTelephone,
  firstEmail,
  firstPlaceId,
  secondCity,
  secondAddress,
  secondPostal,
  secondTelephone,
  secondEmail,
  secondPlaceId,
}) => (
  <div className="locations">
    <GoogleMaps
      city={firstCity}
      address={firstAddress}
      postal={firstPostal}
      telephone={firstTelephone}
      email={firstEmail}
      place_id={firstPlaceId}
    />
    <GoogleMaps
      city={secondCity}
      address={secondAddress}
      postal={secondPostal}
      telephone={secondTelephone}
      email={secondEmail}
      place_id={secondPlaceId}
    />
  </div>
);
