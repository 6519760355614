/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseurl } from 'utils';
import logoWhite from 'static/logo/logo_sand.png';
import LOGO_DTDGROUP_SAND from 'static/logo/1_LOGO_DTDGROUP_SAND.png';
import './Burger.css';
import SubMenuPoint from './BurgerSubMenuPoint';

const DirectMenuPoint = ({ obj, menuHandle }) => {
  const { title } = obj.regularpage;
  let { slug } = obj.regularpage;
  slug =
    title === 'Home' || title === 'News'
      ? slug
      : `/page/${obj.regularpage.slug}`;
  return (
    <Link to={slug} className="burger_menu_points" onClick={menuHandle}>
      <p className="burger_menu_points">{title}</p>
    </Link>
  );
};

const BurgerButton = ({ displayBurger, menuHandle }) => {
  const outerStyle = { backgroundColor: displayBurger ? '#212e92' : null };
  const innerStyle = { backgroundColor: displayBurger ? '#eb4034' : null };
  return (
    <div onClick={menuHandle} className="burger_icon" style={outerStyle}>
      <div className="burger_line" style={innerStyle} />
      <div className="burger_line" style={innerStyle} />
      <div className="burger_line" style={innerStyle} />
    </div>
  );
};

const BurgerMenu = ({ burgerStyle, menuPoints }) => (
  <div className="burger_menu" style={burgerStyle}>
    <Link to="/">
      <div className="burger_menu_img_div">
        <img className="burger_menu_img" alt="menu" src={logoWhite} />
      </div>
    </Link>
    <div className="burger_menu_points_div">{menuPoints}</div>
  </div>
);

export default ({ match, sitemap }) => {
  const [displayBurger, setDisplayBurger] = useState(false);
  const [menuPoints, setMenuPoints] = useState(false);
  const [burgerStyle, setBurgerStyle] = useState({ display: 'none' });
  const menuHandle = () => {
    setBurgerStyle(displayBurger ? { display: 'none' } : { display: 'block' });
    setDisplayBurger(!displayBurger);
  };
  const handleClick = (e) => {
    const { className } = e.target;
    const bMenuClassees = [
      'burger_menu',
      'burger_menu_img_div',
      'burger_menu_img',
      'burger_menu_points',
      'burger_menu_points_div',
      'burger_submenu_points',
      'burger_line',
      'burger_icon',
    ];
    if (displayBurger && !bMenuClassees.includes(className)) menuHandle();
  };
  const windowResize = () => {
    if (window.innerWidth > 720 && displayBurger) menuHandle();
  };
  useEffect(() => {
    const url = `${baseurl}/frontend/menu/?format=json`;
    const home = { regularpage: { title: 'Home', slug: '/', link: '/' } };
    const news = {
      regularpage: { title: 'News', slug: '/news', link: '/news' },
    };

    if (sitemap) {
      const mergedPagesTop = sitemap.hasNews
        ? sitemap.sitemap.concat(news)
        : sitemap.sitemap;
      const mergedPages = [home].concat(mergedPagesTop);
      setMenuPoints(
        mergedPages.map((obj) =>
          obj.submenu ? (
            <SubMenuPoint
              obj={obj}
              key={obj.submenu.name}
              menuHandle={menuHandle}
            />
          ) : (
            <DirectMenuPoint
              obj={obj}
              key={obj.regularpage.title}
              menuHandle={menuHandle}
            />
          )
        )
      );
    }

    window.addEventListener('resize', windowResize);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('resize', windowResize);
      window.removeEventListener('click', handleClick);
    };
  }, [match]);
  return (
    <div>
      {match.isExact ? (
        <img
          className="burger_menu_dtd_logo"
          alt="logo"
          src={LOGO_DTDGROUP_SAND}
        />
      ) : null}
      <BurgerMenu burgerStyle={burgerStyle} menuPoints={menuPoints} />
      <BurgerButton displayBurger={displayBurger} menuHandle={menuHandle} />
    </div>
  );
};
