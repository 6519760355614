import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from 'utils';
import { RichText, Date } from 'components/blocks';
import { HeaderPictureText } from 'components/blocks/HeaderPicture';

const Page = ({
  page_picture,
  title,
  title_color_black,
  published,
  body,
}) => (
  <>
    <HeaderPictureText
      image={page_picture}
      titleLight={title_color_black}
      title={title}
    />
    <Date published={published} />
    <RichText text={body} />
  </>
);

export default ({ slug }) => {
  const [page, setpage] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${baseurl}/api/v2/pages/?slug=${slug}&type=frontend.BlogPage&fields=*`;
    axios
      .get(url, { cancelToken: source.token })
      .then(({ data }) => setpage(<Page {...data.items[0]} />));
    return () => source.cancel();
  }, [slug]);
  return page;
};
