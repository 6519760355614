import React from 'react';
import 'components/blocks/css/Picture.css';
import { TimelineMax } from 'gsap';
import GSAP from 'react-gsap-enhancer';
import { mediaLink } from 'utils';

const Pic = (props) => {
  const {
    imgs,
    opacity,
    zIndex,
    name,
  } = props;
  return (
    <div
      className="picture"
      style={{
        position: 'absolute',
        opacity,
        zIndex,
        backgroundImage: `url(${mediaLink(imgs.picture_1.big)})`,
      }}
      name={name}
    />
  );
};

class Picture extends React.Component {
  componentDidMount() {
    this.anim = this.addAnimation(this.begin);
    this.anim.play();
  }

  begin = ({ target }) => {
    const col1 = target.find({ name: 'col1' });
    const col2 = target.find({ name: 'col2' });
    const col3 = target.find({ name: 'col3' });
    const col4 = target.find({ name: 'col4' });
    return new TimelineMax({ repeat: -1 })
      .to(col1, 4.0, { opacity: 1 })

      .to(col1, 0.0, { zIndex: 0 })
      .to(col2, 0.0, { zIndex: 1 })
      .to(col2, 1.5, { opacity: 1 })
      .to(col1, 0.0, { opacity: 0 })
      .to(col2, 4.0, { opacity: 1 })

      .to(col2, 0.0, { zIndex: 0 })
      .to(col3, 0.0, { zIndex: 1 })
      .to(col3, 1.5, { opacity: 1 })
      .to(col2, 0.0, { opacity: 0 })
      .to(col3, 4.0, { opacity: 1 })

      .to(col3, 0.0, { zIndex: 0 })
      .to(col4, 0.0, { zIndex: 1 })
      .to(col4, 1.5, { opacity: 1 })
      .to(col3, 0.0, { opacity: 0 })
      .to(col4, 4.0, { opacity: 1 })

      .to(col4, 0.0, { zIndex: 0 })
      .to(col1, 0.0, { zIndex: 1 })
      .to(col1, 1.5, { opacity: 1 })
      .to(col4, 0.0, { opacity: 0 });
  }

  render() {
    const { imgs } = this.props;
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <Pic imgs={imgs.picture_1.big} opacity={1} zIndex={1} name="col1" />
        <Pic imgs={imgs.picture_2.big} opacity={0} zIndex={0} name="col2" />
        <Pic imgs={imgs.picture_3.big} opacity={0} zIndex={0} name="col3" />
        <Pic imgs={imgs.picture_4.big} opacity={0} zIndex={0} name="col4" />
      </div>
    );
  }
}

const GSAPContact = GSAP()(Picture);
export default GSAPContact;
