/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from 'utils';

// const mapSiteMap = (items) => {
//   const site = [];
//   items.forEach((item) => {
//     const items_len = item.meta.html_url.split('/').length;
//     if (item.meta.type === 'frontend.RegularsCollection') site.push({ collection: [], ...item });
//     else if (item.meta.type === 'frontend.Regular' && items_len === 6) site.push(item);
//     else if (item.meta.type === 'frontend.Regular' && items_len === 7) site[site.length - 1]
// .collection.push(item);
//   });
//   return site;
// };

const mapSiteMap = (items) => {
  const site = [];
  items.forEach((item) => {
    const items_len = item.meta.html_url.split('/').length;
    if (item.meta.type === 'frontend.RegularsCollection') {
      site.push({
        submenu: { name: item.title, regularpage: [] },
        regularpage: null,
      });
    } else if (
      (item.meta.type === 'frontend.Regular' ||
        item.meta.type === 'frontend.ProductCollection') &&
      items_len === 6
    ) {
      site.push({
        regularpage: {
          title: item.title,
          slug: item.meta.slug,
          link: item.meta.slug,
          id: item.id,
        },
      });
    } else if (
      (item.meta.type === 'frontend.Regular' ||
        item.meta.type === 'frontend.ProductCollection') &&
      items_len === 7
    ) {
      site[site.length - 1].submenu.regularpage.push({
        title: item.title,
        slug: item.meta.slug,
        id: item.id,
      });
    }
  });
  const hasNews = items.some((item) => item.meta.type === 'frontend.Blogs');
  return { sitemap: site, hasNews };
};

const useSitemap = () => {
  const [sitemap, setsitemap] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/api/v2/pages/?type=frontend.RegularsCollection,frontend.Regular,frontend.ProductCollection,frontend.Blogs`;
    const source = axios.CancelToken.source();
    axios
      .get(url, { cancelToken: source.token })
      .then(({ data: { items } }) => setsitemap(mapSiteMap(items)))
      .catch(() => null);
    return () => {
      source.cancel();
    };
  }, []);
  return sitemap;
};

export default useSitemap;
