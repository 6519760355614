import capi from './capi';
import isMobile from './isMobile';
import mediaLink from './mediaLink';
import parseTime from './parseTime';
import sluggify from './sluggify';
import PreloadImg from './PreloadImage';
import validateEmail from './validateEmail';

const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque convallis, massa sed gravida posuere, odio velit pharetra eros, nec facilisis elit ex vel ipsum. Integer pretium urna eget iaculis laoreet. Duis eu pharetra lacus. Fusce mollis, ante sit amet suscipit viverra, risus metus posuere lacus, nec auctor augue ex eget velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla et consectetur velit. Nullam vitae elit id libero.';
const baseurl = 'https://cms.dtdg.dk';
const staticurl = 'https://d31h9snu0uokht.cloudfront.net';
const siteurl = 'http://localhost:3000';

export {
  baseurl,
  staticurl,
  siteurl,
  lorem,
  capi,
  isMobile,
  mediaLink,
  parseTime,
  sluggify,
  validateEmail,
  PreloadImg,
};
