import React from 'react';
import './css/Textbox.css';

export default ({ title }) => (
  <div className="textbox_outer">
    <div className="textbox">
      <p className="textbox_title">{title}</p>
    </div>
  </div>
);
