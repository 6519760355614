import React, { useState } from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import { PreloadImg } from 'utils';
import logoSand from 'static/logo/1_LOGO_DTDGROUP_SAND.png';
import logoBlue from 'static/logo/1_LOGO_DTDGROUP_BLUE.png';

export default ({ obj }) => {
  const { slug, title } = obj;
  const [linkStyle, setLinkStyle] = useState(logoSand);
  return (
    <div className="menuFlexPoint">
      <PreloadImg alt="logoHover" src={logoBlue} />
      <PreloadImg alt="logo" src={logoSand} />
      <Link
        onMouseEnter={() => setLinkStyle(logoBlue)}
        onMouseLeave={() => setLinkStyle(logoSand)}
        className="menuPoint_a"
        to={slug}
      >
        <img alt={title} src={linkStyle} className="menuPoint_img" />
      </Link>
    </div>
  );
};
