import React from 'react';
import './Blogs.css';

export default ({ newPageClick, pages_string }) => (
  <div className="dropdown_wrap">
    <div className="dropdown">
      <p onClick={newPageClick}>{pages_string}</p>
    </div>
  </div>
);
