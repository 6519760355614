import React from 'react';
import '../css/Picture.css';
import { useImage } from 'hooks';
import getPos from './utils';

const HeaderPicture = ({ image, children }) => {
  const [img, tags] = useImage(image);
  const [backgroundPositionX, backgroundPositionY] = getPos(tags);
  return (
    <div
      className="picture"
      name="picture"
      style={{
        backgroundImage: `url(${img})`,
        backgroundPositionY,
        backgroundPositionX,
      }}
    >
      {children}
    </div>
  );
};

export default HeaderPicture;
