import React from 'react';
import { useImageSize } from 'hooks';
import '../css/Picture.css';
import HeaderPicture from './HeaderPicture';

const HeaderPictureText = ({ image, title, titleLight }) => {
  const [fontSize, lineHeight] = useImageSize(title);
  return (
    <HeaderPicture image={image}>
      <p
        className="picture_text"
        style={{
          lineHeight,
          fontSize,
          color: titleLight ? '#f9f0d8' : '#20201e',
        }}
      >
        {title}
      </p>
    </HeaderPicture>
  );
};

export default HeaderPictureText;
