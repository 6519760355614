import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from 'utils';
import { useImage } from 'hooks';
import { Title, RichText } from 'components/blocks';
import { HeaderPictureText } from 'components/blocks/HeaderPicture';
import './RentalPackages.css';

const Package = ({ item }) => {
  const [img] = useImage(item.image);
  return (
    <div className="packages">
      <div
        className="packages_picture"
        style={{
          backgroundImage: `url(${img})`,
        }}
      />
      <div className="packages_in">
        <p className="packages_name">{item.title}</p>
        <p
          className="packages_summary"
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        />
      </div>
    </div>
  );
};

const Page = ({ content, title, price, text }) => {
  const img = content[0].value[0]?.value[0].image;

  return (
    <>
      <HeaderPictureText image={img} titleLight title={title} />
      <Title title={`Price: ${price}`} />
      <RichText text={text} />
      <div
        style={{
          margin: '0 auto',
          width: '80%',
        }}
      >
        {content[0].value[0].value.map((pack) => (
          <Package item={pack} />
        ))}
      </div>
    </>
  );
};

export default ({ pack }) => {
  const [page, setpage] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${baseurl}/api/v2/pages/?type=frontend.Products&slug=${pack}&fields=*`;
    axios
      .get(url, {
        cancelToken: source.token,
      })
      .then(({ data }) => setpage(<Page {...data.items[0]} />));
    return () => source.cancel();
  }, [pack]);
  return page;
};
