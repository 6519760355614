import React from 'react';
import './css/Textbox.css';

export default ({ text }) => (
  <div className="textbox_outer">
    <div className="textbox">
      <p className="textbox_p" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </div>
);
