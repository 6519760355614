import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useProducts } from 'hooks';
import { baseurl } from 'utils';
import { Title, RichText, NameForm, Contacts } from 'components/blocks';
import { HeaderPictureText } from 'components/blocks/HeaderPicture';

const PageTop = ({
  page_picture,
  sub_title,
  title,
  title_color_black,
  body,
}) => (
  <>
    <HeaderPictureText
      image={page_picture}
      titleLight={title_color_black}
      title={title}
    />
    <Title title={sub_title} />
    <RichText text={body} />
  </>
);

const PageBottom = ({ employee, email }) => (
  <>
    <Contacts employee={[employee]} />
    <NameForm email={email} />
  </>
);

export default () => {
  const [pageTop, setpageTop] = useState(null);
  const [pageBottom, setPageBottom] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${baseurl}/api/v2/pages/?type=frontend.ProductCollection&fields=*`;
    axios.get(url, { cancelToken: source.token }).then(({ data }) => {
      setpageTop(<PageTop {...data.items[0]} />);
      setPageBottom(<PageBottom {...data.items[0]} />);
    });
    return () => source.cancel();
  }, []);
  const products = useProducts();
  return (
    <>
      {pageTop}
      {products}
      {pageBottom}
    </>
  );
};
