import React from 'react';

const OutLink = ({ outsource_link, title }) => (
  <div className="page_link">
    <p className="page_link_head">Visit the official website:</p>
    <a
      className="page_link_a"
      href={outsource_link}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </a>
  </div>
);

export default OutLink;
