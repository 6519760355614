import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl, capi } from 'utils';
import { Textbox } from 'components/blocks';

export default (props) => {
  const [top, setTop] = useState(null);
  const [textbox, setTextbox] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${baseurl}/api/v2/pages/9`;
    axios.get(url, { cancelToken: source.token }).then(({ data }) => {
      setTop(<p className="picture_text">{capi(data.title)}</p>);
      setTextbox(<Textbox text={data.body} />);
    });
    return () => { source.cancel(); };
  }, [props]);
  return (
    <div>
      {top}
      {textbox}
    </div>
  );
};
