import React from 'react';
import Contact from './Contact';

const Contacts = ({ employee }) => (
  <div className="contact">
    <p className="contact_head">Contacts</p>
    {employee.map(
      (o) =>
        o && (
          <Contact
            image={o?.picture.small || o?.picture.medium_square_crop}
            navn={o?.name}
            telefon={o?.telephone}
            funktion={o?.function}
            email={o?.email}
            key={o?.name}
          />
        )
    )}
  </div>
);

export default Contacts;
