import React from 'react';
import { Link } from 'react-router-dom';
import { useImage } from 'hooks';
import './RentalPackages.css';

export const Package = ({ item }) => {
  const prod = item.content.find((obj) => obj.type === 'products')?.value[0]
    ?.value[0].image;
  const [img] = useImage(prod);

  return (
    <Link to={`/rental/${item.meta.slug}`}>
      <div className="packages">
        <div
          className="packages_picture"
          style={{ backgroundImage: `url(${img})` }}
        />
        <div className="packages_in">
          <p className="packages_name">{item.title}</p>
          <p className="packages_price">{`Price: ${item.price}`}</p>
          <p
            className="packages_summary"
            dangerouslySetInnerHTML={{ __html: item.summary }}
          />
        </div>
      </div>
    </Link>
  );
};

export default ({ items }) => (
  <div>
    <div className="contact">
      <p className="contact_head">Packages</p>
      {items.map((item) => (
        <Package item={item} />
      ))}
    </div>
  </div>
);
