import React, { useState, useEffect } from 'react';
import './css/Video.css';
import axios from 'axios';
import { baseurl, mediaLink, isMobile } from 'utils';
import VideoWorkaround from 'components/common/VideoWorkaround';

const mobileStyle = {
  left: '50%',
  top: '50%',
  height: '100%',
  WebkitTransform: 'translateY(-50%) translateX(-50%)',
  position: 'absolute',
  zIndex: 2,
  opacity: 1,
};
const divStyle = {
  position: 'fixed',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: -5,
};

export default (props) => {
  const [vid, setVid] = useState(null);
  const [front, setFront] = useState(null);
  const [vidPlaying, setVidPlaying] = useState(false);
  useEffect(() => {
    const url = `${baseurl}/frontend/front_page_video/?format=json`;
    const source = axios.CancelToken.source();
    axios.get(url, { cancelToken: source.token }).then((res) => {
      const { video, video_small, video_still } = res.data;
      const src = isMobile() ? mediaLink(video_small) : mediaLink(video);
      const desktopStyle = {
        background: `url(${video_still}) no-repeat center center`,
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        opacity: 1,
        zIndex: 2,
      };
      setFront(
        isMobile()
          ? <img src={video_still} alt="video_still" style={mobileStyle} />
          : <div name="picture" style={desktopStyle} />,
      );
      setVid(
        <VideoWorkaround
          vidIsPlaying={() => { setVidPlaying(true); }}
          src={src}
          back={video_still}
        />,
      );
    });
    return (() => { source.cancel(); });
  }, [props]);
  return (
    <div style={divStyle}>
      {vidPlaying ? null : front}
      {vid}
    </div>
  );
};
