import React from 'react';
import './css/VolunteerLinks.css';

const Button = ({ link, text }) => (
  <div className="frivillig_buttons_button">
    <a className="frivillig_buttons_button_link" target="_blank" rel="noreferrer" href={link}>
      <div className="frivillig_buttons_button_div">
        {text}
      </div>
    </a>
  </div>
);

export default ({
  firstText,
  firstLink,
  secondText,
  secondLink,
}) => (
  <div className="frivillig_buttons">
    <Button text={firstText} link={firstLink} />
    <Button text={secondText} link={secondLink} />
  </div>
);
