/* eslint-disable function-paren-newline */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useImage, useDocument } from 'hooks';
import { baseurl } from 'utils';
import {
  Textbox,
  OutLinkLogo,
  OutLink,
  HeaderVid,
  Contacts,
  NameForm,
  Title,
  TwoLinks,
  RichText,
  ContactPage2 as TwoMaps,
  Partners2 as Partners,
} from 'components/blocks';
import {
  HeaderPictureText,
  HeaderPictureLogo,
} from 'components/blocks/HeaderPicture';

const TitleAndText = ({ title, text }) => (
  <Textbox subtitle={title} text={text} />
);

const Link = ({ link, text }) => <OutLink outsource_link={link} title={text} />;

const LinkLogo = ({ link, logo }) => (
  <OutLinkLogo outsource_link={link} logo={logo} />
);

const HeaderVideo = ({ video, videoSmall, image, logo }) => {
  const [logo2] = useImage(logo);
  const [image2] = useImage(image);
  const [video2] = useDocument(video);
  const [videoSmall2] = useDocument(videoSmall);
  return (
    <HeaderVid
      videolink={video2}
      videolinkSmall={videoSmall2}
      logo={logo2}
      videoStill={image2}
    />
  );
};

const Employees = ({ 0: { value: employees } }) => {
  const [employee, setEmployee] = useState([]);
  useEffect(() => {
    const url = `${baseurl}/frontend/employees/`;
    const body = JSON.stringify({
      employees: employees.map((_employee) => _employee.employee),
    });
    const source = axios.CancelToken.source();
    axios
      .post(url, body, {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        const sortedEmployees = employees.map(({ employee: _employee }) =>
          data.find(({ id }) => id === _employee)
        );
        setEmployee(sortedEmployees);
      });
    return () => source.cancel();
  }, [employees]);
  return <Contacts employee={employee} />;
};

const PageCompMap = {
  title_and_text: TitleAndText,
  title: Title,
  link: Link,
  link_logo: LinkLogo,
  image_header_text: HeaderPictureText,
  image_header_logo: HeaderPictureLogo,
  video_header_logo: HeaderVideo,
  email_form: NameForm,
  rich_text: RichText,
  two_links: TwoLinks,
  partners: Partners,
  employees: Employees,
  two_maps: TwoMaps,
};

const Page = ({ content }) =>
  content.map(({ type, value }) => {
    const Comp = PageCompMap[type];
    return <Comp {...value} />;
  });

export default ({ slug }) => {
  const [page, setpage] = useState(null);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const url = `${baseurl}/api/v2/pages/?slug=${slug}`;
    axios.get(url, { cancelToken: source.token }).then(({ data }) => {
      const urlin = `${baseurl}/api/v2/pages/${data.items[0].id}/`;
      axios
        .get(urlin, { cancelToken: source.token })
        .then(({ data: datain }) => {
          setpage(<Page {...datain} />);
        });
    });
    return () => source.cancel();
  }, [slug]);
  return page;
};
