import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl, staticurl } from 'utils';

const useGetDocument = (imageId) => {
  const [loadedlogo, setloadedlogo] = useState(null);
  const [tags, settags] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/api/v2/documents/${imageId}/`;
    axios.get(url).then(({ data }) => {
      const documentId = data.meta.download_url.split('/').slice(-1)[0];
      const documentUrl = `${staticurl}/documents/${documentId}`;
      setloadedlogo(documentUrl);
      settags(data.meta.tags);
    });
  }, [imageId]);
  return [loadedlogo, tags];
};

export default useGetDocument;
