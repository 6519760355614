import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from 'utils';
import NewRentalPackages from 'components/pages/rental/RentalPackages';

const useProducts = () => {
  const [products, setproducts] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/api/v2/pages/?type=frontend.Products&fields=*`;
    const source = axios.CancelToken.source();
    axios
      .get(url, { cancelToken: source.token })
      .then(({ data: { items } }) => {
        const prods = <NewRentalPackages items={items} />;
        setproducts(prods);
      })
      .catch(() => null);
    return () => {
      source.cancel();
    };
  }, []);
  return products;
};

export default useProducts;
