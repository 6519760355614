import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { validateEmail, baseurl } from 'utils';

export default ({ email }) => {
  const history = useHistory();
  const [user_email, setUser_email] = useState('');
  const [text, setText] = useState('');
  const [[error, errorCol], setError] = useState(['', 'black']);
  const handleChangeEmail = (event) => { setUser_email(event.target.value); };
  const handleChangeText = (event) => { setText(event.target.value); };
  const handleSubmit = (event) => {
    if (!validateEmail(user_email)) setError(['Please enter a valid email', '#c6746b']);
    else if (text.length > 1000) setError(['Your message is too long', '#c6746b']);
    else if (text.length < 20) setError(['Your message is too short', '#c6746b']);
    else {
      const url = `${baseurl}/frontend/email/`;
      const slug = history.location.pathname.split('/').pop();
      axios.post(url, {
        user_email,
        text,
        slug,
        email,
      }).then((res) => {
        const goodResp = res.status === 200;
        setError(goodResp ? ['Your message was sent', '#63a367'] : ['Your message was not sent - contact email above instead', '#c6746b']);
      });
    }
    event.preventDefault();
  };
  return (
    <div className="page_form">
      <p className="page_link_head">Have a question? Send us an email</p>
      <form onSubmit={handleSubmit}>
        <div className="form_flex">
          <input placeholder="Your email" className="form_field_email" type="text" value={user_email} onChange={handleChangeEmail} tabIndex={0} />
          <input className="form_submit" type="submit" value="Send" tabIndex={0} />
          <textarea placeholder="Your message" className="form_field_text" type="text" value={text} onChange={handleChangeText} tabIndex={0} />
        </div>
      </form>
      <p className="page_link_head error_msg" style={{ color: errorCol }}>{error}</p>
    </div>
  );
};
