import React from 'react';
import { useImage } from 'hooks';

const OutLinkLogo = ({ outsource_link, logo }) => {
  const [loadedlogo] = useImage(logo);
  return (
    <div className="page_link">
      <p className="page_link_head">Visit the official website:</p>
      <a href={outsource_link} target="_blank" rel="noreferrer">
        <img alt="linklogo" className="page_link_logo" src={loadedlogo} />
      </a>
    </div>
  );
};

export default OutLinkLogo;
