import { useState, useEffect } from 'react';

const useImageSize = (title) => {
  const [fontSize, setFontSize] = useState(100);
  const [lineHeight, setLineHeight] = useState(100);

  const windowResize = () => {
    const len = title.length;
    if (window.innerWidth > 500 && window.innerWidth < 900 && len >= 69) {
      setFontSize(40); setLineHeight('50px');
    } else if (window.innerWidth <= 500 && len >= 69) {
      setFontSize(30); setLineHeight('40px');
    } else if (window.innerWidth > 900 && len >= 69) {
      setFontSize(80); setLineHeight('80px');
    } else if (window.innerWidth < 768) {
      setFontSize(50); setLineHeight(null);
    } else {
      setFontSize(100); setLineHeight('100px');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
    return (() => {
      window.removeEventListener('resize', windowResize);
    });
  });

  return [fontSize, lineHeight];
};

export default useImageSize;
