import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { mediaLink, baseurl } from 'utils';
import { FestivalLogo, Video } from 'components/pages/frontpage';
import 'components/pages/frontpage/css/Logo.css';

export default (props) => {
  const [logos, setlogos] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/frontend/logo_frontpage/?format=json`;
    const source = axios.CancelToken.source();
    axios.get(url, { cancelToken: source.token }).then((res) => {
      const logos1 = res.data.map(({
        name,
        link,
        logo,
        logo_hover,
      }) => (
        <FestivalLogo
          festival={name}
          key={name}
          link={link}
          logo={mediaLink(logo.full_size)}
          logoHover={mediaLink(logo_hover)}
        />
      ));
      setlogos(logos1);
    });
    return () => { source.cancel(); };
  }, [props]);
  return (
    <div>
      <Video />
      <div className="festivalFlex1">
        { logos }
      </div>
    </div>
  );
};
