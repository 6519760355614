import React from 'react';
import { parseTime } from 'utils';

const Date = ({ published }) => (
  <p
    style={{
      color: '#f9f0d8',
      width: '80%',
      margin: '0 auto',
      padding: '30px 0px 0px 0px',
    }}
  >
    {parseTime(published)}
  </p>
);

export default Date;
