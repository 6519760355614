import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from 'utils';
import Post from './Post';
import Pages from './Pages';
import Dropdown from './Dropdown';
import './Blogs.css';

export default () => {
  const [posts, setPosts] = useState(null);
  const [pages_string, setPages_string] = useState(null);
  const [page, setPage] = useState(1);
  const [cat, setCat] = useState('all');
  const page_size = 10;

  const newPageClick = (e) => {
    setPage(parseInt(e.target.id, 10));
  };

  const updateList = (e) => {
    setCat(e.target.innerHTML);
    setPage(1);
  };
  useEffect(() => {
    const limit = (page - 1) * page_size + 10;
    const offset = (page - 1) * page_size;
    // const url =
    // `${baseurl}/frontend/page_new/${cat}/?format=json&limit=${limit}&offset=${offset}`;
    const url = `${baseurl}/api/v2/pages/?type=frontend.BlogPage&fields=*&limit=${limit}&offset=${offset}${cat === 'all' ? '' : `&category=${cat}`}&order=-published`;
    const source = axios.CancelToken.source();
    axios
      .get(url, { cancelToken: source.token })
      .then(({ data: { items, meta: { total_count } } }) => {
        setPosts(
          items.map(
            ({
              title,
              meta: { slug },
              category,
              published,
              body,
              page_picture,
              ppoi,
            }) => (
              <Post
                title={title}
                slug={slug}
                category={category}
                created={published}
                text={body}
                ppoi={ppoi}
                page_picture={page_picture}
                key={title}
              />
            ),
          ),
        );
        const pages = Math.ceil(total_count / page_size);
        setPages_string(
          [...Array(pages).keys()].map((item) => item + 1 === page
            ? (
              <span key={item + 1} id={item + 1}>{`${item + 1} `}</span>
            ) : (
              <span
                key={item + 1}
                id={item + 1}
                style={{ fontFamily: 'ProximaNova-Regular' }}
              >
                {`${item + 1} `}
              </span>
            )),
        );
      });
    return () => { source.cancel(); };
  }, [cat, page]);

  return (
    <div className="page_container">
      <p className="picture_text" style={{ height: 130 }}>News</p>
      <Pages newPageClick={newPageClick} pages_string={pages_string} />
      <Dropdown updateList={updateList} />
      <div className="blog_posts">
        {posts}
      </div>
      <Pages newPageClick={newPageClick} pages_string={pages_string} />
    </div>
  );
};
