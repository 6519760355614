import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl, staticurl } from 'utils';

const useGetImage = (imageId) => {
  const [loadedlogo, setloadedlogo] = useState(null);
  const [tags, settags] = useState(null);
  useEffect(() => {
    if (typeof imageId === 'string' && imageId.includes('cloudfront')) {
      setloadedlogo(imageId);
    } else {
      const url = `${baseurl}/api/v2/images/${imageId}/`;
      axios.get(url).then(({ data }) => {
        const imageName = data.meta.download_url.split('/').slice(-1)[0];
        const imageUrl = `${staticurl}/original_images/${imageName}`;
        setloadedlogo(imageUrl);
        settags(data.meta.tags);
      });
    }
  }, [imageId]);
  return [loadedlogo, tags];
};

export default useGetImage;
