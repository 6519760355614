import React, { useState, useEffect } from 'react';
import './css/Header_vid.css';
import { isMobile } from 'utils';
import VideoWorkaround from 'components/common/VideoWorkaround';

export default ({
  videolink,
  videolinkSmall,
  videoStill,
  logo,
  title,
  color,
}) => {
  const [vid, setVid] = useState(null);
  const [vidPlaying, setVidPlaying] = useState(false);
  const [front, setFront] = useState(null);
  useEffect(() => {
    const update = () => {
      const src = isMobile() ? videolinkSmall : videolink;
      setFront(
        isMobile() ? (
          <img
            src={videoStill}
            alt="video_still"
            className="picture_overvid_mobile"
          />
        ) : (
          <div
            className="picture_overvid"
            name="picture"
            style={{
              background: `url(${videoStill}) no-repeat center center`,
            }}
          />
        ),
      );
      setVid(
        <VideoWorkaround
          vidIsPlaying={() => {
            setVidPlaying(true);
          }}
          src={src}
          back={videoStill}
        />,
      );
    };
    update();
  }, [videolink, videolinkSmall, videoStill]);
  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {
        logo
          ? <img alt="logolink" className="header_logo" src={logo} />
          : <p className="picture_text" style={{ position: 'absolute', zIndex: 2, color: color ? '#20201e' : '#f9f0d8' }}>{title}</p>
      }
      {vidPlaying ? null : front}
      <div className="head_video">
        {vid}
      </div>
    </div>
  );
};
