/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { parseTime } from 'utils';
import { Link } from 'react-router-dom';
import './Blogs.css';

export default (props) => {
  const {
    created,
    slug,
    category,
    text,
    page_picture,
    ppoi,
    title,
  } = props;
  const [managedTitle, setManagedTitle] = useState(title);
  const windowResize = () => {
    if (
      window.innerWidth > 500 &&
      window.innerWidth < 900 &&
      title.length >= 70
    ) {
      setManagedTitle(`${title.substring(0, 70)}...`);
    } else if (window.innerWidth < 500 && title.length >= 60) {
      setManagedTitle(`${title.substring(0, 60)}...`);
    } else {
      setManagedTitle(title);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, [slug]);
  const [h, w] = ppoi
    .replace('(', '')
    .replace(')', '')
    .split(', ')
    .map((e) => e.replace('0.', ''));
  const thumbnail = page_picture
    .replace('/blog/', '/images/__sized__/blog/')
    .replace('.png', `-crop-c0-${h}__0-${w}-300x300.png`)
    .replace('.jpeg', `-crop-c0-${h}__0-${w}-300x300-70.jpeg`)
    .replace('.jpg', `-crop-c0-${h}__0-${w}-300x300-70.jpg`);
  return (
    <Link to={`/news/${slug}`}>
      <div className="blog_posts_post">
        <div
          className="blog_posts_post_picture"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <div className="blog_posts_post_in">
          <p className="blog_posts_post_title">{managedTitle}</p>
          <p className="blog_posts_post_author">{category}</p>
          <p className="blog_posts_post_time">{parseTime(created)}</p>
          <p
            className="blog_posts_post_text"
            dangerouslySetInnerHTML={{
              __html: `${text.substring(0, 550)}...`,
            }}
          />
        </div>
      </div>
    </Link>
  );
};
