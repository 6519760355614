import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl, mediaLink, PreloadImg } from 'utils';
import { Link } from 'react-router-dom';
import logoWhite from 'static/logo/logo_sand.png';
import './Footer.css';

const FooterLink = ({ txt, to }) => <Link className="footer_p" to={to}>{txt}</Link>;

const SomeIcon = ({ logo, logoHover, link }) => {
  const [linkStyle, setLinkStyle] = useState(logo);
  return (
    <div
      onMouseEnter={() => setLinkStyle(logoHover)}
      onMouseLeave={() => setLinkStyle(logo)}
      className="some_icon"
    >
      <PreloadImg alt="logoHover" src={logoHover} />
      <PreloadImg alt="logo" src={logo} />
      <a href={`https://${link}`} target="_blank" rel="noreferrer">
        <img className="some_icon_img" src={linkStyle} alt="" />
      </a>
    </div>
  );
};

export default (props) => {
  const [somes, setSomes] = useState(null);
  useEffect(() => {
    const url = `${baseurl}/frontend/some/?format=json`;
    const source = axios.CancelToken.source();
    axios.get(url, { cancelToken: source.token }).then((res) => {
      setSomes(res.data.map(({ logo_hover, logo, link }) => (
        <SomeIcon
          logoHover={mediaLink(logo_hover)}
          logo={mediaLink(logo)}
          key={link}
          link={link}
        />
      )));
    });
    return () => { source.cancel(); };
  }, [props]);
  return (
    <div className="footer">
      <img className="footer_img" alt="footer" src={logoWhite} />
      <div className="footer_text">
        <FooterLink to="/" txt="Front page" />
        <FooterLink to="/privacy_policy" txt="Privacy policy" />
      </div>
      <div className="somes">
        {somes}
      </div>
      <p className="copyright">
        © DTD Group
      </p>
    </div>
  );
};
