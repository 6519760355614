import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { useSitemap } from 'hooks';
import 'static/fonts/MyFontsWebfontsKit.css';
import { Partners } from 'components/blocks';
import Footer from './elements/footer/Footer';
import Menu from './elements/menu/Menu';
import Burger from './elements/menu/Burger';
import {
  Front,
  Page,
  Rental,
  Blog,
  BlogPost,
  RentalPackagePage,
  Datapolitik
} from './pages';
import './App.css';

const NewPageRoute = ({ match }) => <Page slug={match.params.name} />;
const BlogPostRoute = ({ match }) => <BlogPost slug={match.params.post} />;
const RentalPackageRoute = ({ match }) => (
  <RentalPackagePage pack={match.params.pack} />
);

const FooterPages = () => (
  <>
    <Switch>
      <Route path="/page/rental" component={Rental} />
      <Route path="/page/:name" exact component={NewPageRoute} />
      <Route path="/rental/:pack" component={RentalPackageRoute} />
      <Route path="/partners" component={Partners} />
      <Route path="/news/:post" component={BlogPostRoute} />
      <Route path="/news" component={Blog} />
      <Route path="/page" exact component={Page} />
      <Route path="/privacy_policy" component={Datapolitik} />
    </Switch>
    <Footer />
  </>
);

const Menus = ({ match, history }) => {
  const sitemap = useSitemap();
  const props = { match, sitemap };
  return sitemap && (
    <>
      <Menu history={history} {...props} />
      <Burger {...props} />
    </>
  );
};

const HistoryMedium = ({ match, history }) => (
  <>
    <Menus match={match} history={history} />
    <Switch>
      <Route exact path="/" component={Front} />
      <Route path="/:something" component={FooterPages} />
    </Switch>
  </>
);

const Main = () => (
  <div className="maincontent">
    <Switch>
      <Route path="/" component={HistoryMedium} />
    </Switch>
  </div>
);

export default () => (
  <div className="App">
    <Router>
      <Main />
    </Router>
  </div>
);

// const GSAPContact = GSAP()(App);
// export default GSAPContact;
