import React from 'react';
import './Blogs.css';

const categories = [
  'All',
  'DTD Group',
  'NorthSide',
  'Tinderbox',
  'DTD Concerts',
  'Crew',
  'Rental',
  'Crowd Safety',
  'Partner- and group sales',
];

export default ({ updateList }) => (
  <div className="dropdown_wrap">
    <div className="dropdown">
      <p>Categories</p>
      <div onClick={updateList} className="dropdown-content">
        {categories.map((category) => (
          <p className="dropdown_cat">{category}</p>
        ))}
      </div>
    </div>
  </div>
);
