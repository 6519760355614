import React, { useState } from 'react';
import './Burger.css';
import { Link } from 'react-router-dom';

export default ({ obj, menuHandle }) => {
  const [displaysub, setDisplaysub] = useState(false);
  return (
    <div>
      <p onClick={() => setDisplaysub(!displaysub)} className="burger_menu_points">{obj.submenu.name}</p>
      { displaysub ? (
        obj.submenu.regularpage.map(({ slug, title }) => (
          <Link to={`/page/${slug}`} className="burger_menu_points" onClick={() => { menuHandle(); setDisplaysub(!displaysub); }} key={title}>
            <p className="burger_submenu_points">
              {title}
            </p>
          </Link>
        ))
      ) : null }
    </div>
  );
};
